// @generated by protobuf-ts 2.9.3
// @generated from protobuf file "sni.proto" (syntax proto3)
// tslint:disable
import { DeviceNWA } from "./sni";
import type { NWACommandResponse } from "./sni";
import type { NWACommandRequest } from "./sni";
import { DeviceInfo } from "./sni";
import type { FieldsResponse } from "./sni";
import type { FieldsRequest } from "./sni";
import { DeviceFilesystem } from "./sni";
import type { BootFileResponse } from "./sni";
import type { BootFileRequest } from "./sni";
import type { GetFileResponse } from "./sni";
import type { GetFileRequest } from "./sni";
import type { PutFileResponse } from "./sni";
import type { PutFileRequest } from "./sni";
import type { RenameFileResponse } from "./sni";
import type { RenameFileRequest } from "./sni";
import type { RemoveFileResponse } from "./sni";
import type { RemoveFileRequest } from "./sni";
import type { MakeDirectoryResponse } from "./sni";
import type { MakeDirectoryRequest } from "./sni";
import type { ReadDirectoryResponse } from "./sni";
import type { ReadDirectoryRequest } from "./sni";
import { DeviceMemory } from "./sni";
import type { DuplexStreamingCall } from "@protobuf-ts/runtime-rpc";
import type { MultiWriteMemoryResponse } from "./sni";
import type { MultiWriteMemoryRequest } from "./sni";
import type { MultiReadMemoryResponse } from "./sni";
import type { MultiReadMemoryRequest } from "./sni";
import type { SingleWriteMemoryResponse } from "./sni";
import type { SingleWriteMemoryRequest } from "./sni";
import type { SingleReadMemoryResponse } from "./sni";
import type { SingleReadMemoryRequest } from "./sni";
import type { DetectMemoryMappingResponse } from "./sni";
import type { DetectMemoryMappingRequest } from "./sni";
import { DeviceControl } from "./sni";
import type { PauseToggleEmulationResponse } from "./sni";
import type { PauseToggleEmulationRequest } from "./sni";
import type { PauseEmulationResponse } from "./sni";
import type { PauseEmulationRequest } from "./sni";
import type { ResetToMenuResponse } from "./sni";
import type { ResetToMenuRequest } from "./sni";
import type { ResetSystemResponse } from "./sni";
import type { ResetSystemRequest } from "./sni";
import type { RpcTransport } from "@protobuf-ts/runtime-rpc";
import type { ServiceInfo } from "@protobuf-ts/runtime-rpc";
import { Devices } from "./sni";
import { stackIntercept } from "@protobuf-ts/runtime-rpc";
import type { DevicesResponse } from "./sni";
import type { DevicesRequest } from "./sni";
import type { UnaryCall } from "@protobuf-ts/runtime-rpc";
import type { RpcOptions } from "@protobuf-ts/runtime-rpc";
// ////////////////////////////////////////////////////////////////////////////////////////////////
// services
// ////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * @generated from protobuf service Devices
 */
export interface IDevicesClient {
    /**
     * detect and list devices currently connected to the system:
     *
     * @generated from protobuf rpc: ListDevices(DevicesRequest) returns (DevicesResponse);
     */
    listDevices(input: DevicesRequest, options?: RpcOptions): UnaryCall<DevicesRequest, DevicesResponse>;
}
// ////////////////////////////////////////////////////////////////////////////////////////////////
// services
// ////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * @generated from protobuf service Devices
 */
export class DevicesClient implements IDevicesClient, ServiceInfo {
    typeName = Devices.typeName;
    methods = Devices.methods;
    options = Devices.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * detect and list devices currently connected to the system:
     *
     * @generated from protobuf rpc: ListDevices(DevicesRequest) returns (DevicesResponse);
     */
    listDevices(input: DevicesRequest, options?: RpcOptions): UnaryCall<DevicesRequest, DevicesResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<DevicesRequest, DevicesResponse>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service DeviceControl
 */
export interface IDeviceControlClient {
    /**
     * only available if DeviceCapability ResetSystem is present
     *
     * @generated from protobuf rpc: ResetSystem(ResetSystemRequest) returns (ResetSystemResponse);
     */
    resetSystem(input: ResetSystemRequest, options?: RpcOptions): UnaryCall<ResetSystemRequest, ResetSystemResponse>;
    /**
     * only available if DeviceCapability ResetToMenu is present
     *
     * @generated from protobuf rpc: ResetToMenu(ResetToMenuRequest) returns (ResetToMenuResponse);
     */
    resetToMenu(input: ResetToMenuRequest, options?: RpcOptions): UnaryCall<ResetToMenuRequest, ResetToMenuResponse>;
    /**
     * only available if DeviceCapability PauseUnpauseEmulation is present
     *
     * @generated from protobuf rpc: PauseUnpauseEmulation(PauseEmulationRequest) returns (PauseEmulationResponse);
     */
    pauseUnpauseEmulation(input: PauseEmulationRequest, options?: RpcOptions): UnaryCall<PauseEmulationRequest, PauseEmulationResponse>;
    /**
     * only available if DeviceCapability PauseToggleEmulation is present
     *
     * @generated from protobuf rpc: PauseToggleEmulation(PauseToggleEmulationRequest) returns (PauseToggleEmulationResponse);
     */
    pauseToggleEmulation(input: PauseToggleEmulationRequest, options?: RpcOptions): UnaryCall<PauseToggleEmulationRequest, PauseToggleEmulationResponse>;
}
/**
 * @generated from protobuf service DeviceControl
 */
export class DeviceControlClient implements IDeviceControlClient, ServiceInfo {
    typeName = DeviceControl.typeName;
    methods = DeviceControl.methods;
    options = DeviceControl.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * only available if DeviceCapability ResetSystem is present
     *
     * @generated from protobuf rpc: ResetSystem(ResetSystemRequest) returns (ResetSystemResponse);
     */
    resetSystem(input: ResetSystemRequest, options?: RpcOptions): UnaryCall<ResetSystemRequest, ResetSystemResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ResetSystemRequest, ResetSystemResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * only available if DeviceCapability ResetToMenu is present
     *
     * @generated from protobuf rpc: ResetToMenu(ResetToMenuRequest) returns (ResetToMenuResponse);
     */
    resetToMenu(input: ResetToMenuRequest, options?: RpcOptions): UnaryCall<ResetToMenuRequest, ResetToMenuResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<ResetToMenuRequest, ResetToMenuResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * only available if DeviceCapability PauseUnpauseEmulation is present
     *
     * @generated from protobuf rpc: PauseUnpauseEmulation(PauseEmulationRequest) returns (PauseEmulationResponse);
     */
    pauseUnpauseEmulation(input: PauseEmulationRequest, options?: RpcOptions): UnaryCall<PauseEmulationRequest, PauseEmulationResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<PauseEmulationRequest, PauseEmulationResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * only available if DeviceCapability PauseToggleEmulation is present
     *
     * @generated from protobuf rpc: PauseToggleEmulation(PauseToggleEmulationRequest) returns (PauseToggleEmulationResponse);
     */
    pauseToggleEmulation(input: PauseToggleEmulationRequest, options?: RpcOptions): UnaryCall<PauseToggleEmulationRequest, PauseToggleEmulationResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<PauseToggleEmulationRequest, PauseToggleEmulationResponse>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service DeviceMemory
 */
export interface IDeviceMemoryClient {
    /**
     * detect the current memory mapping for the given device by reading 00:FFB0 header:
     *
     * @generated from protobuf rpc: MappingDetect(DetectMemoryMappingRequest) returns (DetectMemoryMappingResponse);
     */
    mappingDetect(input: DetectMemoryMappingRequest, options?: RpcOptions): UnaryCall<DetectMemoryMappingRequest, DetectMemoryMappingResponse>;
    /**
     * read a single memory segment with a given size from the given device:
     *
     * @generated from protobuf rpc: SingleRead(SingleReadMemoryRequest) returns (SingleReadMemoryResponse);
     */
    singleRead(input: SingleReadMemoryRequest, options?: RpcOptions): UnaryCall<SingleReadMemoryRequest, SingleReadMemoryResponse>;
    /**
     * write a single memory segment with given data to the given device:
     *
     * @generated from protobuf rpc: SingleWrite(SingleWriteMemoryRequest) returns (SingleWriteMemoryResponse);
     */
    singleWrite(input: SingleWriteMemoryRequest, options?: RpcOptions): UnaryCall<SingleWriteMemoryRequest, SingleWriteMemoryResponse>;
    /**
     * read multiple memory segments with given sizes from the given device:
     *
     * @generated from protobuf rpc: MultiRead(MultiReadMemoryRequest) returns (MultiReadMemoryResponse);
     */
    multiRead(input: MultiReadMemoryRequest, options?: RpcOptions): UnaryCall<MultiReadMemoryRequest, MultiReadMemoryResponse>;
    /**
     * write multiple memory segments with given data to the given device:
     *
     * @generated from protobuf rpc: MultiWrite(MultiWriteMemoryRequest) returns (MultiWriteMemoryResponse);
     */
    multiWrite(input: MultiWriteMemoryRequest, options?: RpcOptions): UnaryCall<MultiWriteMemoryRequest, MultiWriteMemoryResponse>;
    /**
     * stream read multiple memory segments with given sizes from the given device:
     *
     * @generated from protobuf rpc: StreamRead(stream MultiReadMemoryRequest) returns (stream MultiReadMemoryResponse);
     */
    streamRead(options?: RpcOptions): DuplexStreamingCall<MultiReadMemoryRequest, MultiReadMemoryResponse>;
    /**
     * stream write multiple memory segments with given data to the given device:
     *
     * @generated from protobuf rpc: StreamWrite(stream MultiWriteMemoryRequest) returns (stream MultiWriteMemoryResponse);
     */
    streamWrite(options?: RpcOptions): DuplexStreamingCall<MultiWriteMemoryRequest, MultiWriteMemoryResponse>;
}
/**
 * @generated from protobuf service DeviceMemory
 */
export class DeviceMemoryClient implements IDeviceMemoryClient, ServiceInfo {
    typeName = DeviceMemory.typeName;
    methods = DeviceMemory.methods;
    options = DeviceMemory.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * detect the current memory mapping for the given device by reading 00:FFB0 header:
     *
     * @generated from protobuf rpc: MappingDetect(DetectMemoryMappingRequest) returns (DetectMemoryMappingResponse);
     */
    mappingDetect(input: DetectMemoryMappingRequest, options?: RpcOptions): UnaryCall<DetectMemoryMappingRequest, DetectMemoryMappingResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<DetectMemoryMappingRequest, DetectMemoryMappingResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * read a single memory segment with a given size from the given device:
     *
     * @generated from protobuf rpc: SingleRead(SingleReadMemoryRequest) returns (SingleReadMemoryResponse);
     */
    singleRead(input: SingleReadMemoryRequest, options?: RpcOptions): UnaryCall<SingleReadMemoryRequest, SingleReadMemoryResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<SingleReadMemoryRequest, SingleReadMemoryResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * write a single memory segment with given data to the given device:
     *
     * @generated from protobuf rpc: SingleWrite(SingleWriteMemoryRequest) returns (SingleWriteMemoryResponse);
     */
    singleWrite(input: SingleWriteMemoryRequest, options?: RpcOptions): UnaryCall<SingleWriteMemoryRequest, SingleWriteMemoryResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<SingleWriteMemoryRequest, SingleWriteMemoryResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * read multiple memory segments with given sizes from the given device:
     *
     * @generated from protobuf rpc: MultiRead(MultiReadMemoryRequest) returns (MultiReadMemoryResponse);
     */
    multiRead(input: MultiReadMemoryRequest, options?: RpcOptions): UnaryCall<MultiReadMemoryRequest, MultiReadMemoryResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<MultiReadMemoryRequest, MultiReadMemoryResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * write multiple memory segments with given data to the given device:
     *
     * @generated from protobuf rpc: MultiWrite(MultiWriteMemoryRequest) returns (MultiWriteMemoryResponse);
     */
    multiWrite(input: MultiWriteMemoryRequest, options?: RpcOptions): UnaryCall<MultiWriteMemoryRequest, MultiWriteMemoryResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<MultiWriteMemoryRequest, MultiWriteMemoryResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * stream read multiple memory segments with given sizes from the given device:
     *
     * @generated from protobuf rpc: StreamRead(stream MultiReadMemoryRequest) returns (stream MultiReadMemoryResponse);
     */
    streamRead(options?: RpcOptions): DuplexStreamingCall<MultiReadMemoryRequest, MultiReadMemoryResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<MultiReadMemoryRequest, MultiReadMemoryResponse>("duplex", this._transport, method, opt);
    }
    /**
     * stream write multiple memory segments with given data to the given device:
     *
     * @generated from protobuf rpc: StreamWrite(stream MultiWriteMemoryRequest) returns (stream MultiWriteMemoryResponse);
     */
    streamWrite(options?: RpcOptions): DuplexStreamingCall<MultiWriteMemoryRequest, MultiWriteMemoryResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<MultiWriteMemoryRequest, MultiWriteMemoryResponse>("duplex", this._transport, method, opt);
    }
}
/**
 * @generated from protobuf service DeviceFilesystem
 */
export interface IDeviceFilesystemClient {
    /**
     * @generated from protobuf rpc: ReadDirectory(ReadDirectoryRequest) returns (ReadDirectoryResponse);
     */
    readDirectory(input: ReadDirectoryRequest, options?: RpcOptions): UnaryCall<ReadDirectoryRequest, ReadDirectoryResponse>;
    /**
     * @generated from protobuf rpc: MakeDirectory(MakeDirectoryRequest) returns (MakeDirectoryResponse);
     */
    makeDirectory(input: MakeDirectoryRequest, options?: RpcOptions): UnaryCall<MakeDirectoryRequest, MakeDirectoryResponse>;
    /**
     * @generated from protobuf rpc: RemoveFile(RemoveFileRequest) returns (RemoveFileResponse);
     */
    removeFile(input: RemoveFileRequest, options?: RpcOptions): UnaryCall<RemoveFileRequest, RemoveFileResponse>;
    /**
     * @generated from protobuf rpc: RenameFile(RenameFileRequest) returns (RenameFileResponse);
     */
    renameFile(input: RenameFileRequest, options?: RpcOptions): UnaryCall<RenameFileRequest, RenameFileResponse>;
    /**
     * @generated from protobuf rpc: PutFile(PutFileRequest) returns (PutFileResponse);
     */
    putFile(input: PutFileRequest, options?: RpcOptions): UnaryCall<PutFileRequest, PutFileResponse>;
    /**
     * @generated from protobuf rpc: GetFile(GetFileRequest) returns (GetFileResponse);
     */
    getFile(input: GetFileRequest, options?: RpcOptions): UnaryCall<GetFileRequest, GetFileResponse>;
    /**
     * @generated from protobuf rpc: BootFile(BootFileRequest) returns (BootFileResponse);
     */
    bootFile(input: BootFileRequest, options?: RpcOptions): UnaryCall<BootFileRequest, BootFileResponse>;
}
/**
 * @generated from protobuf service DeviceFilesystem
 */
export class DeviceFilesystemClient implements IDeviceFilesystemClient, ServiceInfo {
    typeName = DeviceFilesystem.typeName;
    methods = DeviceFilesystem.methods;
    options = DeviceFilesystem.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: ReadDirectory(ReadDirectoryRequest) returns (ReadDirectoryResponse);
     */
    readDirectory(input: ReadDirectoryRequest, options?: RpcOptions): UnaryCall<ReadDirectoryRequest, ReadDirectoryResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<ReadDirectoryRequest, ReadDirectoryResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: MakeDirectory(MakeDirectoryRequest) returns (MakeDirectoryResponse);
     */
    makeDirectory(input: MakeDirectoryRequest, options?: RpcOptions): UnaryCall<MakeDirectoryRequest, MakeDirectoryResponse> {
        const method = this.methods[1], opt = this._transport.mergeOptions(options);
        return stackIntercept<MakeDirectoryRequest, MakeDirectoryResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RemoveFile(RemoveFileRequest) returns (RemoveFileResponse);
     */
    removeFile(input: RemoveFileRequest, options?: RpcOptions): UnaryCall<RemoveFileRequest, RemoveFileResponse> {
        const method = this.methods[2], opt = this._transport.mergeOptions(options);
        return stackIntercept<RemoveFileRequest, RemoveFileResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: RenameFile(RenameFileRequest) returns (RenameFileResponse);
     */
    renameFile(input: RenameFileRequest, options?: RpcOptions): UnaryCall<RenameFileRequest, RenameFileResponse> {
        const method = this.methods[3], opt = this._transport.mergeOptions(options);
        return stackIntercept<RenameFileRequest, RenameFileResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: PutFile(PutFileRequest) returns (PutFileResponse);
     */
    putFile(input: PutFileRequest, options?: RpcOptions): UnaryCall<PutFileRequest, PutFileResponse> {
        const method = this.methods[4], opt = this._transport.mergeOptions(options);
        return stackIntercept<PutFileRequest, PutFileResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: GetFile(GetFileRequest) returns (GetFileResponse);
     */
    getFile(input: GetFileRequest, options?: RpcOptions): UnaryCall<GetFileRequest, GetFileResponse> {
        const method = this.methods[5], opt = this._transport.mergeOptions(options);
        return stackIntercept<GetFileRequest, GetFileResponse>("unary", this._transport, method, opt, input);
    }
    /**
     * @generated from protobuf rpc: BootFile(BootFileRequest) returns (BootFileResponse);
     */
    bootFile(input: BootFileRequest, options?: RpcOptions): UnaryCall<BootFileRequest, BootFileResponse> {
        const method = this.methods[6], opt = this._transport.mergeOptions(options);
        return stackIntercept<BootFileRequest, BootFileResponse>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service DeviceInfo
 */
export interface IDeviceInfoClient {
    /**
     * @generated from protobuf rpc: FetchFields(FieldsRequest) returns (FieldsResponse);
     */
    fetchFields(input: FieldsRequest, options?: RpcOptions): UnaryCall<FieldsRequest, FieldsResponse>;
}
/**
 * @generated from protobuf service DeviceInfo
 */
export class DeviceInfoClient implements IDeviceInfoClient, ServiceInfo {
    typeName = DeviceInfo.typeName;
    methods = DeviceInfo.methods;
    options = DeviceInfo.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: FetchFields(FieldsRequest) returns (FieldsResponse);
     */
    fetchFields(input: FieldsRequest, options?: RpcOptions): UnaryCall<FieldsRequest, FieldsResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<FieldsRequest, FieldsResponse>("unary", this._transport, method, opt, input);
    }
}
/**
 * @generated from protobuf service DeviceNWA
 */
export interface IDeviceNWAClient {
    /**
     * @generated from protobuf rpc: NWACommand(NWACommandRequest) returns (NWACommandResponse);
     */
    nWACommand(input: NWACommandRequest, options?: RpcOptions): UnaryCall<NWACommandRequest, NWACommandResponse>;
}
/**
 * @generated from protobuf service DeviceNWA
 */
export class DeviceNWAClient implements IDeviceNWAClient, ServiceInfo {
    typeName = DeviceNWA.typeName;
    methods = DeviceNWA.methods;
    options = DeviceNWA.options;
    constructor(private readonly _transport: RpcTransport) {
    }
    /**
     * @generated from protobuf rpc: NWACommand(NWACommandRequest) returns (NWACommandResponse);
     */
    nWACommand(input: NWACommandRequest, options?: RpcOptions): UnaryCall<NWACommandRequest, NWACommandResponse> {
        const method = this.methods[0], opt = this._transport.mergeOptions(options);
        return stackIntercept<NWACommandRequest, NWACommandResponse>("unary", this._transport, method, opt, input);
    }
}
