// @generated by protobuf-ts 2.9.3
// @generated from protobuf file "sni.proto" (syntax proto3)
// tslint:disable
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
// ////////////////////////////////////////////////////////////////////////////////////////////////
// devices messages
// ////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * @generated from protobuf message DevicesRequest
 */
export interface DevicesRequest {
    /**
     * optional list of device kind filters
     *
     * @generated from protobuf field: repeated string kinds = 1;
     */
    kinds: string[]; // TODO: repeated DeviceCapability capabilities;
}
/**
 * @generated from protobuf message DevicesResponse
 */
export interface DevicesResponse {
    /**
     * @generated from protobuf field: repeated DevicesResponse.Device devices = 1;
     */
    devices: DevicesResponse_Device[];
}
/**
 * @generated from protobuf message DevicesResponse.Device
 */
export interface DevicesResponse_Device {
    /**
     * URI that describes exactly how to connect to the device, e.g.:
     * RetroArch:  "ra://127.0.0.1:55355"
     * FX Pak Pro: "fxpakpro://./dev/cu.usbmodemDEMO000000001" (MacOS)
     *             "fxpakpro://./COM4"                         (Windows)
     *             "fxpakpro://./dev/ttyACM0"                  (Linux)
     * uri is used as the unique identifier of the device for clients to refer to
     *
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * friendly display name of the device
     *
     * @generated from protobuf field: string displayName = 2;
     */
    displayName: string;
    /**
     * device kind, e.g. "fxpakpro", "retroarch", "lua"
     *
     * @generated from protobuf field: string kind = 3;
     */
    kind: string;
    /**
     * all device capabilities:
     *
     * @generated from protobuf field: repeated DeviceCapability capabilities = 4;
     */
    capabilities: DeviceCapability[];
    /**
     * default address space for the device:
     *
     * @generated from protobuf field: AddressSpace defaultAddressSpace = 5;
     */
    defaultAddressSpace: AddressSpace;
    /**
     * [DEPRECATED] console system supported, e.g. "snes", "n64"
     * since devices can support multiple systems, it's better to fetch platform from DeviceInfo.FetchFields method
     *
     * @deprecated
     * @generated from protobuf field: string system = 6 [deprecated = true];
     */
    system: string;
}
// ////////////////////////////////////////////////////////////////////////////////////////////////
// control messages
// ////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * @generated from protobuf message ResetSystemRequest
 */
export interface ResetSystemRequest {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
}
/**
 * @generated from protobuf message ResetSystemResponse
 */
export interface ResetSystemResponse {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
}
/**
 * @generated from protobuf message ResetToMenuRequest
 */
export interface ResetToMenuRequest {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
}
/**
 * @generated from protobuf message ResetToMenuResponse
 */
export interface ResetToMenuResponse {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
}
/**
 * @generated from protobuf message PauseEmulationRequest
 */
export interface PauseEmulationRequest {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * true to pause emulation, false to unpause
     *
     * @generated from protobuf field: bool paused = 2;
     */
    paused: boolean;
}
/**
 * @generated from protobuf message PauseEmulationResponse
 */
export interface PauseEmulationResponse {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: bool paused = 2;
     */
    paused: boolean;
}
/**
 * @generated from protobuf message PauseToggleEmulationRequest
 */
export interface PauseToggleEmulationRequest {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
}
/**
 * @generated from protobuf message PauseToggleEmulationResponse
 */
export interface PauseToggleEmulationResponse {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
}
// ////////////////////////////////////////////////////////////////////////////////////////////////
// memory messages
// ////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * @generated from protobuf message DetectMemoryMappingRequest
 */
export interface DetectMemoryMappingRequest {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * optional fallback value to set in case detection fails for some reason
     *
     * @generated from protobuf field: optional MemoryMapping fallbackMemoryMapping = 2;
     */
    fallbackMemoryMapping?: MemoryMapping;
    /**
     * optional ROM header (from bus address $00:FFB0, at least $30 bytes long) to use for detection
     * if not provided, the header will be read from the device
     *
     * @generated from protobuf field: optional bytes romHeader00FFB0 = 3;
     */
    romHeader00FFB0?: Uint8Array;
}
/**
 * @generated from protobuf message DetectMemoryMappingResponse
 */
export interface DetectMemoryMappingResponse {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * the memory mapping mode detected
     *
     * @generated from protobuf field: MemoryMapping memoryMapping = 2;
     */
    memoryMapping: MemoryMapping;
    /**
     * true if confident we detected a mapping; false if using a fallback or default value
     *
     * @generated from protobuf field: bool confidence = 3;
     */
    confidence: boolean;
    /**
     * the ROM header read from $00:FFB0, length is $50 bytes if server reads it, otherwise length of `romHeader00FFB0` from request
     *
     * @generated from protobuf field: bytes romHeader00FFB0 = 4;
     */
    romHeader00FFB0: Uint8Array;
}
/**
 * @generated from protobuf message ReadMemoryRequest
 */
export interface ReadMemoryRequest {
    /**
     * @generated from protobuf field: uint32 requestAddress = 1;
     */
    requestAddress: number;
    /**
     * @generated from protobuf field: AddressSpace requestAddressSpace = 2;
     */
    requestAddressSpace: AddressSpace;
    /**
     * @generated from protobuf field: MemoryMapping requestMemoryMapping = 4;
     */
    requestMemoryMapping: MemoryMapping;
    /**
     * @generated from protobuf field: uint32 size = 3;
     */
    size: number;
}
/**
 * @generated from protobuf message ReadMemoryResponse
 */
export interface ReadMemoryResponse {
    /**
     * @generated from protobuf field: uint32 requestAddress = 1;
     */
    requestAddress: number;
    /**
     * @generated from protobuf field: AddressSpace requestAddressSpace = 2;
     */
    requestAddressSpace: AddressSpace;
    /**
     * @generated from protobuf field: MemoryMapping requestMemoryMapping = 6;
     */
    requestMemoryMapping: MemoryMapping;
    /**
     * the address sent to the device and its space
     *
     * @generated from protobuf field: uint32 deviceAddress = 3;
     */
    deviceAddress: number;
    /**
     * @generated from protobuf field: AddressSpace deviceAddressSpace = 4;
     */
    deviceAddressSpace: AddressSpace;
    /**
     * @generated from protobuf field: bytes data = 5;
     */
    data: Uint8Array;
}
/**
 * @generated from protobuf message WriteMemoryRequest
 */
export interface WriteMemoryRequest {
    /**
     * @generated from protobuf field: uint32 requestAddress = 1;
     */
    requestAddress: number;
    /**
     * @generated from protobuf field: AddressSpace requestAddressSpace = 2;
     */
    requestAddressSpace: AddressSpace;
    /**
     * @generated from protobuf field: MemoryMapping requestMemoryMapping = 4;
     */
    requestMemoryMapping: MemoryMapping;
    /**
     * @generated from protobuf field: bytes data = 3;
     */
    data: Uint8Array;
}
/**
 * @generated from protobuf message WriteMemoryResponse
 */
export interface WriteMemoryResponse {
    /**
     * @generated from protobuf field: uint32 requestAddress = 1;
     */
    requestAddress: number;
    /**
     * @generated from protobuf field: AddressSpace requestAddressSpace = 2;
     */
    requestAddressSpace: AddressSpace;
    /**
     * @generated from protobuf field: MemoryMapping requestMemoryMapping = 6;
     */
    requestMemoryMapping: MemoryMapping;
    /**
     * @generated from protobuf field: uint32 deviceAddress = 3;
     */
    deviceAddress: number;
    /**
     * @generated from protobuf field: AddressSpace deviceAddressSpace = 4;
     */
    deviceAddressSpace: AddressSpace;
    /**
     * @generated from protobuf field: uint32 size = 5;
     */
    size: number;
}
/**
 * @generated from protobuf message SingleReadMemoryRequest
 */
export interface SingleReadMemoryRequest {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: ReadMemoryRequest request = 2;
     */
    request?: ReadMemoryRequest;
}
/**
 * @generated from protobuf message SingleReadMemoryResponse
 */
export interface SingleReadMemoryResponse {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: ReadMemoryResponse response = 2;
     */
    response?: ReadMemoryResponse;
}
/**
 * @generated from protobuf message SingleWriteMemoryRequest
 */
export interface SingleWriteMemoryRequest {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: WriteMemoryRequest request = 2;
     */
    request?: WriteMemoryRequest;
}
/**
 * @generated from protobuf message SingleWriteMemoryResponse
 */
export interface SingleWriteMemoryResponse {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: WriteMemoryResponse response = 2;
     */
    response?: WriteMemoryResponse;
}
/**
 * @generated from protobuf message MultiReadMemoryRequest
 */
export interface MultiReadMemoryRequest {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: repeated ReadMemoryRequest requests = 2;
     */
    requests: ReadMemoryRequest[];
}
/**
 * @generated from protobuf message MultiReadMemoryResponse
 */
export interface MultiReadMemoryResponse {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: repeated ReadMemoryResponse responses = 2;
     */
    responses: ReadMemoryResponse[];
}
/**
 * @generated from protobuf message MultiWriteMemoryRequest
 */
export interface MultiWriteMemoryRequest {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: repeated WriteMemoryRequest requests = 2;
     */
    requests: WriteMemoryRequest[];
}
/**
 * @generated from protobuf message MultiWriteMemoryResponse
 */
export interface MultiWriteMemoryResponse {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: repeated WriteMemoryResponse responses = 2;
     */
    responses: WriteMemoryResponse[];
}
// ////////////////////////////////////////////////////////////////////////////////////////////////
// filesystem messages
// ////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * @generated from protobuf message ReadDirectoryRequest
 */
export interface ReadDirectoryRequest {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: string path = 2;
     */
    path: string;
}
/**
 * @generated from protobuf message DirEntry
 */
export interface DirEntry {
    /**
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * @generated from protobuf field: DirEntryType type = 2;
     */
    type: DirEntryType;
}
/**
 * @generated from protobuf message ReadDirectoryResponse
 */
export interface ReadDirectoryResponse {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: string path = 2;
     */
    path: string;
    /**
     * @generated from protobuf field: repeated DirEntry entries = 3;
     */
    entries: DirEntry[];
}
/**
 * @generated from protobuf message MakeDirectoryRequest
 */
export interface MakeDirectoryRequest {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: string path = 2;
     */
    path: string;
}
/**
 * @generated from protobuf message MakeDirectoryResponse
 */
export interface MakeDirectoryResponse {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: string path = 2;
     */
    path: string;
}
/**
 * @generated from protobuf message RemoveFileRequest
 */
export interface RemoveFileRequest {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: string path = 2;
     */
    path: string;
}
/**
 * @generated from protobuf message RemoveFileResponse
 */
export interface RemoveFileResponse {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: string path = 2;
     */
    path: string;
}
/**
 * @generated from protobuf message RenameFileRequest
 */
export interface RenameFileRequest {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: string path = 2;
     */
    path: string;
    /**
     * @generated from protobuf field: string newFilename = 3;
     */
    newFilename: string;
}
/**
 * @generated from protobuf message RenameFileResponse
 */
export interface RenameFileResponse {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: string path = 2;
     */
    path: string;
    /**
     * @generated from protobuf field: string newFilename = 3;
     */
    newFilename: string;
}
/**
 * @generated from protobuf message PutFileRequest
 */
export interface PutFileRequest {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: string path = 2;
     */
    path: string;
    /**
     * @generated from protobuf field: bytes data = 3;
     */
    data: Uint8Array;
}
/**
 * @generated from protobuf message PutFileResponse
 */
export interface PutFileResponse {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: string path = 2;
     */
    path: string;
    /**
     * @generated from protobuf field: uint32 size = 3;
     */
    size: number;
}
/**
 * @generated from protobuf message GetFileRequest
 */
export interface GetFileRequest {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: string path = 2;
     */
    path: string;
}
/**
 * @generated from protobuf message GetFileResponse
 */
export interface GetFileResponse {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: string path = 2;
     */
    path: string;
    /**
     * @generated from protobuf field: uint32 size = 3;
     */
    size: number;
    /**
     * @generated from protobuf field: bytes data = 4;
     */
    data: Uint8Array;
}
/**
 * @generated from protobuf message BootFileRequest
 */
export interface BootFileRequest {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: string path = 2;
     */
    path: string;
}
/**
 * @generated from protobuf message BootFileResponse
 */
export interface BootFileResponse {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: string path = 2;
     */
    path: string;
}
// ////////////////////////////////////////////////////////////////////////////////////////////////
// info messages
// ////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * @generated from protobuf message FieldsRequest
 */
export interface FieldsRequest {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: repeated Field fields = 2;
     */
    fields: Field[];
}
/**
 * @generated from protobuf message FieldsResponse
 */
export interface FieldsResponse {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: repeated Field fields = 2;
     */
    fields: Field[];
    /**
     * @generated from protobuf field: repeated string values = 3;
     */
    values: string[];
}
// ////////////////////////////////////////////////////////////////////////////////////////////////
// NWA messages (emu-nwaccess protocol pass through)
// ////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * @generated from protobuf message NWACommandRequest
 */
export interface NWACommandRequest {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * emu-nwaccess command name:
     *
     * @generated from protobuf field: string command = 2;
     */
    command: string;
    /**
     * command arguments:
     *
     * @generated from protobuf field: string args = 3;
     */
    args: string;
    /**
     * an optional binary argument:
     *
     * @generated from protobuf field: optional bytes binaryArg = 4;
     */
    binaryArg?: Uint8Array;
}
/**
 * @generated from protobuf message NWACommandResponse
 */
export interface NWACommandResponse {
    /**
     * @generated from protobuf field: string uri = 1;
     */
    uri: string;
    /**
     * @generated from protobuf field: repeated NWACommandResponse.NWAASCIIItem asciiReply = 2;
     */
    asciiReply: NWACommandResponse_NWAASCIIItem[];
    /**
     * @generated from protobuf field: optional bytes binaryReplay = 3;
     */
    binaryReplay?: Uint8Array;
}
/**
 * @generated from protobuf message NWACommandResponse.NWAASCIIItem
 */
export interface NWACommandResponse_NWAASCIIItem {
    /**
     * @generated from protobuf field: map<string, string> item = 1;
     */
    item: {
        [key: string]: string;
    };
}
// ////////////////////////////////////////////////////////////////////////////////////////////////
// enums
// ////////////////////////////////////////////////////////////////////////////////////////////////

/**
 * address space used to interpret an address in:
 *
 * @generated from protobuf enum AddressSpace
 */
export enum AddressSpace {
    /**
     * The default is the FX Pak Pro / SD2SNES's address space:
     * $00_0000..$DF_FFFF =   ROM contents, linearly mapped
     * $E0_0000..$EF_FFFF =  SRAM contents, linearly mapped
     * $F5_0000..$F6_FFFF =  WRAM contents, linearly mapped
     * $F7_0000..$F7_FFFF =  VRAM contents, linearly mapped
     * $F8_0000..$F8_FFFF =   APU contents, linearly mapped
     * $F9_0000..$F9_01FF = CGRAM contents, linearly mapped
     * $F9_0200..$F9_041F =   OAM contents, linearly mapped
     * $F9_0420..$F9_04FF =  MISC contents, linearly mapped
     * $F9_0500..$F9_06FF =         PPUREG, linearly mapped
     * $F9_0700..$F9_08FF =         CPUREG, linearly mapped
     * translated device address depends on device being talked to and its current MemoryMapping mode
     *
     * SNES
     *
     * @generated from protobuf enum value: FxPakPro = 0;
     */
    FxPakPro = 0,
    /**
     * The SNES's main A-bus; address depends on device's current MemoryMapping mode, e.g. LoROM, HiROM, ExHiROM, etc.
     *
     * @generated from protobuf enum value: SnesABus = 1;
     */
    SnesABus = 1,
    /**
     * Do not do any address translation; simply pass the raw address to the device as-is:
     *
     * @generated from protobuf enum value: Raw = 2;
     */
    Raw = 2
}
/**
 * memory mapping mode of a SNES cart:
 *
 * @generated from protobuf enum MemoryMapping
 */
export enum MemoryMapping {
    /**
     * @generated from protobuf enum value: Unknown = 0;
     */
    Unknown = 0,
    /**
     * @generated from protobuf enum value: HiROM = 1;
     */
    HiROM = 1,
    /**
     * @generated from protobuf enum value: LoROM = 2;
     */
    LoROM = 2,
    /**
     * (48-64Mbit)
     *
     * @generated from protobuf enum value: ExHiROM = 3;
     */
    ExHiROM = 3,
    /**
     * TODO: BSX = 5;
     *
     * @generated from protobuf enum value: SA1 = 4;
     */
    SA1 = 4
}
/**
 * capabilities of a device
 *
 * @generated from protobuf enum DeviceCapability
 */
export enum DeviceCapability {
    /**
     * @generated from protobuf enum value: None = 0;
     */
    None = 0,
    /**
     * @generated from protobuf enum value: ReadMemory = 1;
     */
    ReadMemory = 1,
    /**
     * @generated from protobuf enum value: WriteMemory = 2;
     */
    WriteMemory = 2,
    /**
     * @generated from protobuf enum value: ExecuteASM = 3;
     */
    ExecuteASM = 3,
    /**
     * @generated from protobuf enum value: ResetSystem = 4;
     */
    ResetSystem = 4,
    /**
     * @generated from protobuf enum value: PauseUnpauseEmulation = 5;
     */
    PauseUnpauseEmulation = 5,
    /**
     * @generated from protobuf enum value: PauseToggleEmulation = 6;
     */
    PauseToggleEmulation = 6,
    /**
     * @generated from protobuf enum value: ResetToMenu = 7;
     */
    ResetToMenu = 7,
    /**
     * @generated from protobuf enum value: FetchFields = 8;
     */
    FetchFields = 8,
    /**
     * @generated from protobuf enum value: ReadDirectory = 10;
     */
    ReadDirectory = 10,
    /**
     * @generated from protobuf enum value: MakeDirectory = 11;
     */
    MakeDirectory = 11,
    /**
     * @generated from protobuf enum value: RemoveFile = 12;
     */
    RemoveFile = 12,
    /**
     * @generated from protobuf enum value: RenameFile = 13;
     */
    RenameFile = 13,
    /**
     * @generated from protobuf enum value: PutFile = 14;
     */
    PutFile = 14,
    /**
     * @generated from protobuf enum value: GetFile = 15;
     */
    GetFile = 15,
    /**
     * @generated from protobuf enum value: BootFile = 16;
     */
    BootFile = 16,
    /**
     * @generated from protobuf enum value: NWACommand = 20;
     */
    NWACommand = 20
}
/**
 * fields to query from DeviceInfo.FetchFields
 *
 * @generated from protobuf enum Field
 */
export enum Field {
    /**
     * @generated from protobuf enum value: DeviceName = 0;
     */
    DeviceName = 0,
    /**
     * @generated from protobuf enum value: DeviceVersion = 1;
     */
    DeviceVersion = 1,
    /**
     * @generated from protobuf enum value: DeviceStatus = 2;
     */
    DeviceStatus = 2,
    /**
     * @generated from protobuf enum value: CoreName = 20;
     */
    CoreName = 20,
    /**
     * @generated from protobuf enum value: CoreVersion = 21;
     */
    CoreVersion = 21,
    /**
     * @generated from protobuf enum value: CorePlatform = 22;
     */
    CorePlatform = 22,
    /**
     * @generated from protobuf enum value: RomFileName = 40;
     */
    RomFileName = 40,
    /**
     * @generated from protobuf enum value: RomHashType = 41;
     */
    RomHashType = 41,
    /**
     * @generated from protobuf enum value: RomHashValue = 42;
     */
    RomHashValue = 42
}
/**
 * @generated from protobuf enum DirEntryType
 */
export enum DirEntryType {
    /**
     * @generated from protobuf enum value: Directory = 0;
     */
    Directory = 0,
    /**
     * @generated from protobuf enum value: File = 1;
     */
    File = 1
}
// @generated message type with reflection information, may provide speed optimized methods
class DevicesRequest$Type extends MessageType<DevicesRequest> {
    constructor() {
        super("DevicesRequest", [
            { no: 1, name: "kinds", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DevicesRequest>): DevicesRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.kinds = [];
        if (value !== undefined)
            reflectionMergePartial<DevicesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DevicesRequest): DevicesRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string kinds */ 1:
                    message.kinds.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DevicesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string kinds = 1; */
        for (let i = 0; i < message.kinds.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.kinds[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DevicesRequest
 */
export const DevicesRequest = new DevicesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DevicesResponse$Type extends MessageType<DevicesResponse> {
    constructor() {
        super("DevicesResponse", [
            { no: 1, name: "devices", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DevicesResponse_Device }
        ]);
    }
    create(value?: PartialMessage<DevicesResponse>): DevicesResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.devices = [];
        if (value !== undefined)
            reflectionMergePartial<DevicesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DevicesResponse): DevicesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated DevicesResponse.Device devices */ 1:
                    message.devices.push(DevicesResponse_Device.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DevicesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated DevicesResponse.Device devices = 1; */
        for (let i = 0; i < message.devices.length; i++)
            DevicesResponse_Device.internalBinaryWrite(message.devices[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DevicesResponse
 */
export const DevicesResponse = new DevicesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DevicesResponse_Device$Type extends MessageType<DevicesResponse_Device> {
    constructor() {
        super("DevicesResponse.Device", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "displayName", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "kind", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "capabilities", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["DeviceCapability", DeviceCapability] },
            { no: 5, name: "defaultAddressSpace", kind: "enum", T: () => ["AddressSpace", AddressSpace] },
            { no: 6, name: "system", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<DevicesResponse_Device>): DevicesResponse_Device {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.displayName = "";
        message.kind = "";
        message.capabilities = [];
        message.defaultAddressSpace = 0;
        message.system = "";
        if (value !== undefined)
            reflectionMergePartial<DevicesResponse_Device>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DevicesResponse_Device): DevicesResponse_Device {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* string displayName */ 2:
                    message.displayName = reader.string();
                    break;
                case /* string kind */ 3:
                    message.kind = reader.string();
                    break;
                case /* repeated DeviceCapability capabilities */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.capabilities.push(reader.int32());
                    else
                        message.capabilities.push(reader.int32());
                    break;
                case /* AddressSpace defaultAddressSpace */ 5:
                    message.defaultAddressSpace = reader.int32();
                    break;
                case /* string system = 6 [deprecated = true];*/ 6:
                    message.system = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DevicesResponse_Device, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* string displayName = 2; */
        if (message.displayName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.displayName);
        /* string kind = 3; */
        if (message.kind !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.kind);
        /* repeated DeviceCapability capabilities = 4; */
        if (message.capabilities.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.capabilities.length; i++)
                writer.int32(message.capabilities[i]);
            writer.join();
        }
        /* AddressSpace defaultAddressSpace = 5; */
        if (message.defaultAddressSpace !== 0)
            writer.tag(5, WireType.Varint).int32(message.defaultAddressSpace);
        /* string system = 6 [deprecated = true]; */
        if (message.system !== "")
            writer.tag(6, WireType.LengthDelimited).string(message.system);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DevicesResponse.Device
 */
export const DevicesResponse_Device = new DevicesResponse_Device$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetSystemRequest$Type extends MessageType<ResetSystemRequest> {
    constructor() {
        super("ResetSystemRequest", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ResetSystemRequest>): ResetSystemRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        if (value !== undefined)
            reflectionMergePartial<ResetSystemRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResetSystemRequest): ResetSystemRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResetSystemRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ResetSystemRequest
 */
export const ResetSystemRequest = new ResetSystemRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetSystemResponse$Type extends MessageType<ResetSystemResponse> {
    constructor() {
        super("ResetSystemResponse", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ResetSystemResponse>): ResetSystemResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        if (value !== undefined)
            reflectionMergePartial<ResetSystemResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResetSystemResponse): ResetSystemResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResetSystemResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ResetSystemResponse
 */
export const ResetSystemResponse = new ResetSystemResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetToMenuRequest$Type extends MessageType<ResetToMenuRequest> {
    constructor() {
        super("ResetToMenuRequest", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ResetToMenuRequest>): ResetToMenuRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        if (value !== undefined)
            reflectionMergePartial<ResetToMenuRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResetToMenuRequest): ResetToMenuRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResetToMenuRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ResetToMenuRequest
 */
export const ResetToMenuRequest = new ResetToMenuRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ResetToMenuResponse$Type extends MessageType<ResetToMenuResponse> {
    constructor() {
        super("ResetToMenuResponse", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ResetToMenuResponse>): ResetToMenuResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        if (value !== undefined)
            reflectionMergePartial<ResetToMenuResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ResetToMenuResponse): ResetToMenuResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ResetToMenuResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ResetToMenuResponse
 */
export const ResetToMenuResponse = new ResetToMenuResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PauseEmulationRequest$Type extends MessageType<PauseEmulationRequest> {
    constructor() {
        super("PauseEmulationRequest", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "paused", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<PauseEmulationRequest>): PauseEmulationRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.paused = false;
        if (value !== undefined)
            reflectionMergePartial<PauseEmulationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PauseEmulationRequest): PauseEmulationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* bool paused */ 2:
                    message.paused = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PauseEmulationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* bool paused = 2; */
        if (message.paused !== false)
            writer.tag(2, WireType.Varint).bool(message.paused);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PauseEmulationRequest
 */
export const PauseEmulationRequest = new PauseEmulationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PauseEmulationResponse$Type extends MessageType<PauseEmulationResponse> {
    constructor() {
        super("PauseEmulationResponse", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "paused", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<PauseEmulationResponse>): PauseEmulationResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.paused = false;
        if (value !== undefined)
            reflectionMergePartial<PauseEmulationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PauseEmulationResponse): PauseEmulationResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* bool paused */ 2:
                    message.paused = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PauseEmulationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* bool paused = 2; */
        if (message.paused !== false)
            writer.tag(2, WireType.Varint).bool(message.paused);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PauseEmulationResponse
 */
export const PauseEmulationResponse = new PauseEmulationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PauseToggleEmulationRequest$Type extends MessageType<PauseToggleEmulationRequest> {
    constructor() {
        super("PauseToggleEmulationRequest", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PauseToggleEmulationRequest>): PauseToggleEmulationRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        if (value !== undefined)
            reflectionMergePartial<PauseToggleEmulationRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PauseToggleEmulationRequest): PauseToggleEmulationRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PauseToggleEmulationRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PauseToggleEmulationRequest
 */
export const PauseToggleEmulationRequest = new PauseToggleEmulationRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PauseToggleEmulationResponse$Type extends MessageType<PauseToggleEmulationResponse> {
    constructor() {
        super("PauseToggleEmulationResponse", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<PauseToggleEmulationResponse>): PauseToggleEmulationResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        if (value !== undefined)
            reflectionMergePartial<PauseToggleEmulationResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PauseToggleEmulationResponse): PauseToggleEmulationResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PauseToggleEmulationResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PauseToggleEmulationResponse
 */
export const PauseToggleEmulationResponse = new PauseToggleEmulationResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DetectMemoryMappingRequest$Type extends MessageType<DetectMemoryMappingRequest> {
    constructor() {
        super("DetectMemoryMappingRequest", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "fallbackMemoryMapping", kind: "enum", opt: true, T: () => ["MemoryMapping", MemoryMapping] },
            { no: 3, name: "romHeader00FFB0", kind: "scalar", opt: true, T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<DetectMemoryMappingRequest>): DetectMemoryMappingRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        if (value !== undefined)
            reflectionMergePartial<DetectMemoryMappingRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DetectMemoryMappingRequest): DetectMemoryMappingRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* optional MemoryMapping fallbackMemoryMapping */ 2:
                    message.fallbackMemoryMapping = reader.int32();
                    break;
                case /* optional bytes romHeader00FFB0 */ 3:
                    message.romHeader00FFB0 = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DetectMemoryMappingRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* optional MemoryMapping fallbackMemoryMapping = 2; */
        if (message.fallbackMemoryMapping !== undefined)
            writer.tag(2, WireType.Varint).int32(message.fallbackMemoryMapping);
        /* optional bytes romHeader00FFB0 = 3; */
        if (message.romHeader00FFB0 !== undefined)
            writer.tag(3, WireType.LengthDelimited).bytes(message.romHeader00FFB0);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DetectMemoryMappingRequest
 */
export const DetectMemoryMappingRequest = new DetectMemoryMappingRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DetectMemoryMappingResponse$Type extends MessageType<DetectMemoryMappingResponse> {
    constructor() {
        super("DetectMemoryMappingResponse", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "memoryMapping", kind: "enum", T: () => ["MemoryMapping", MemoryMapping] },
            { no: 3, name: "confidence", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 4, name: "romHeader00FFB0", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<DetectMemoryMappingResponse>): DetectMemoryMappingResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.memoryMapping = 0;
        message.confidence = false;
        message.romHeader00FFB0 = new Uint8Array(0);
        if (value !== undefined)
            reflectionMergePartial<DetectMemoryMappingResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DetectMemoryMappingResponse): DetectMemoryMappingResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* MemoryMapping memoryMapping */ 2:
                    message.memoryMapping = reader.int32();
                    break;
                case /* bool confidence */ 3:
                    message.confidence = reader.bool();
                    break;
                case /* bytes romHeader00FFB0 */ 4:
                    message.romHeader00FFB0 = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DetectMemoryMappingResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* MemoryMapping memoryMapping = 2; */
        if (message.memoryMapping !== 0)
            writer.tag(2, WireType.Varint).int32(message.memoryMapping);
        /* bool confidence = 3; */
        if (message.confidence !== false)
            writer.tag(3, WireType.Varint).bool(message.confidence);
        /* bytes romHeader00FFB0 = 4; */
        if (message.romHeader00FFB0.length)
            writer.tag(4, WireType.LengthDelimited).bytes(message.romHeader00FFB0);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DetectMemoryMappingResponse
 */
export const DetectMemoryMappingResponse = new DetectMemoryMappingResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReadMemoryRequest$Type extends MessageType<ReadMemoryRequest> {
    constructor() {
        super("ReadMemoryRequest", [
            { no: 1, name: "requestAddress", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "requestAddressSpace", kind: "enum", T: () => ["AddressSpace", AddressSpace] },
            { no: 4, name: "requestMemoryMapping", kind: "enum", T: () => ["MemoryMapping", MemoryMapping] },
            { no: 3, name: "size", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<ReadMemoryRequest>): ReadMemoryRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.requestAddress = 0;
        message.requestAddressSpace = 0;
        message.requestMemoryMapping = 0;
        message.size = 0;
        if (value !== undefined)
            reflectionMergePartial<ReadMemoryRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReadMemoryRequest): ReadMemoryRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 requestAddress */ 1:
                    message.requestAddress = reader.uint32();
                    break;
                case /* AddressSpace requestAddressSpace */ 2:
                    message.requestAddressSpace = reader.int32();
                    break;
                case /* MemoryMapping requestMemoryMapping */ 4:
                    message.requestMemoryMapping = reader.int32();
                    break;
                case /* uint32 size */ 3:
                    message.size = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReadMemoryRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 requestAddress = 1; */
        if (message.requestAddress !== 0)
            writer.tag(1, WireType.Varint).uint32(message.requestAddress);
        /* AddressSpace requestAddressSpace = 2; */
        if (message.requestAddressSpace !== 0)
            writer.tag(2, WireType.Varint).int32(message.requestAddressSpace);
        /* MemoryMapping requestMemoryMapping = 4; */
        if (message.requestMemoryMapping !== 0)
            writer.tag(4, WireType.Varint).int32(message.requestMemoryMapping);
        /* uint32 size = 3; */
        if (message.size !== 0)
            writer.tag(3, WireType.Varint).uint32(message.size);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ReadMemoryRequest
 */
export const ReadMemoryRequest = new ReadMemoryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReadMemoryResponse$Type extends MessageType<ReadMemoryResponse> {
    constructor() {
        super("ReadMemoryResponse", [
            { no: 1, name: "requestAddress", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "requestAddressSpace", kind: "enum", T: () => ["AddressSpace", AddressSpace] },
            { no: 6, name: "requestMemoryMapping", kind: "enum", T: () => ["MemoryMapping", MemoryMapping] },
            { no: 3, name: "deviceAddress", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "deviceAddressSpace", kind: "enum", T: () => ["AddressSpace", AddressSpace] },
            { no: 5, name: "data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<ReadMemoryResponse>): ReadMemoryResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.requestAddress = 0;
        message.requestAddressSpace = 0;
        message.requestMemoryMapping = 0;
        message.deviceAddress = 0;
        message.deviceAddressSpace = 0;
        message.data = new Uint8Array(0);
        if (value !== undefined)
            reflectionMergePartial<ReadMemoryResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReadMemoryResponse): ReadMemoryResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 requestAddress */ 1:
                    message.requestAddress = reader.uint32();
                    break;
                case /* AddressSpace requestAddressSpace */ 2:
                    message.requestAddressSpace = reader.int32();
                    break;
                case /* MemoryMapping requestMemoryMapping */ 6:
                    message.requestMemoryMapping = reader.int32();
                    break;
                case /* uint32 deviceAddress */ 3:
                    message.deviceAddress = reader.uint32();
                    break;
                case /* AddressSpace deviceAddressSpace */ 4:
                    message.deviceAddressSpace = reader.int32();
                    break;
                case /* bytes data */ 5:
                    message.data = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReadMemoryResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 requestAddress = 1; */
        if (message.requestAddress !== 0)
            writer.tag(1, WireType.Varint).uint32(message.requestAddress);
        /* AddressSpace requestAddressSpace = 2; */
        if (message.requestAddressSpace !== 0)
            writer.tag(2, WireType.Varint).int32(message.requestAddressSpace);
        /* MemoryMapping requestMemoryMapping = 6; */
        if (message.requestMemoryMapping !== 0)
            writer.tag(6, WireType.Varint).int32(message.requestMemoryMapping);
        /* uint32 deviceAddress = 3; */
        if (message.deviceAddress !== 0)
            writer.tag(3, WireType.Varint).uint32(message.deviceAddress);
        /* AddressSpace deviceAddressSpace = 4; */
        if (message.deviceAddressSpace !== 0)
            writer.tag(4, WireType.Varint).int32(message.deviceAddressSpace);
        /* bytes data = 5; */
        if (message.data.length)
            writer.tag(5, WireType.LengthDelimited).bytes(message.data);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ReadMemoryResponse
 */
export const ReadMemoryResponse = new ReadMemoryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WriteMemoryRequest$Type extends MessageType<WriteMemoryRequest> {
    constructor() {
        super("WriteMemoryRequest", [
            { no: 1, name: "requestAddress", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "requestAddressSpace", kind: "enum", T: () => ["AddressSpace", AddressSpace] },
            { no: 4, name: "requestMemoryMapping", kind: "enum", T: () => ["MemoryMapping", MemoryMapping] },
            { no: 3, name: "data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<WriteMemoryRequest>): WriteMemoryRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.requestAddress = 0;
        message.requestAddressSpace = 0;
        message.requestMemoryMapping = 0;
        message.data = new Uint8Array(0);
        if (value !== undefined)
            reflectionMergePartial<WriteMemoryRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WriteMemoryRequest): WriteMemoryRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 requestAddress */ 1:
                    message.requestAddress = reader.uint32();
                    break;
                case /* AddressSpace requestAddressSpace */ 2:
                    message.requestAddressSpace = reader.int32();
                    break;
                case /* MemoryMapping requestMemoryMapping */ 4:
                    message.requestMemoryMapping = reader.int32();
                    break;
                case /* bytes data */ 3:
                    message.data = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WriteMemoryRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 requestAddress = 1; */
        if (message.requestAddress !== 0)
            writer.tag(1, WireType.Varint).uint32(message.requestAddress);
        /* AddressSpace requestAddressSpace = 2; */
        if (message.requestAddressSpace !== 0)
            writer.tag(2, WireType.Varint).int32(message.requestAddressSpace);
        /* MemoryMapping requestMemoryMapping = 4; */
        if (message.requestMemoryMapping !== 0)
            writer.tag(4, WireType.Varint).int32(message.requestMemoryMapping);
        /* bytes data = 3; */
        if (message.data.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.data);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message WriteMemoryRequest
 */
export const WriteMemoryRequest = new WriteMemoryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class WriteMemoryResponse$Type extends MessageType<WriteMemoryResponse> {
    constructor() {
        super("WriteMemoryResponse", [
            { no: 1, name: "requestAddress", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 2, name: "requestAddressSpace", kind: "enum", T: () => ["AddressSpace", AddressSpace] },
            { no: 6, name: "requestMemoryMapping", kind: "enum", T: () => ["MemoryMapping", MemoryMapping] },
            { no: 3, name: "deviceAddress", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "deviceAddressSpace", kind: "enum", T: () => ["AddressSpace", AddressSpace] },
            { no: 5, name: "size", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<WriteMemoryResponse>): WriteMemoryResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.requestAddress = 0;
        message.requestAddressSpace = 0;
        message.requestMemoryMapping = 0;
        message.deviceAddress = 0;
        message.deviceAddressSpace = 0;
        message.size = 0;
        if (value !== undefined)
            reflectionMergePartial<WriteMemoryResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: WriteMemoryResponse): WriteMemoryResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint32 requestAddress */ 1:
                    message.requestAddress = reader.uint32();
                    break;
                case /* AddressSpace requestAddressSpace */ 2:
                    message.requestAddressSpace = reader.int32();
                    break;
                case /* MemoryMapping requestMemoryMapping */ 6:
                    message.requestMemoryMapping = reader.int32();
                    break;
                case /* uint32 deviceAddress */ 3:
                    message.deviceAddress = reader.uint32();
                    break;
                case /* AddressSpace deviceAddressSpace */ 4:
                    message.deviceAddressSpace = reader.int32();
                    break;
                case /* uint32 size */ 5:
                    message.size = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: WriteMemoryResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint32 requestAddress = 1; */
        if (message.requestAddress !== 0)
            writer.tag(1, WireType.Varint).uint32(message.requestAddress);
        /* AddressSpace requestAddressSpace = 2; */
        if (message.requestAddressSpace !== 0)
            writer.tag(2, WireType.Varint).int32(message.requestAddressSpace);
        /* MemoryMapping requestMemoryMapping = 6; */
        if (message.requestMemoryMapping !== 0)
            writer.tag(6, WireType.Varint).int32(message.requestMemoryMapping);
        /* uint32 deviceAddress = 3; */
        if (message.deviceAddress !== 0)
            writer.tag(3, WireType.Varint).uint32(message.deviceAddress);
        /* AddressSpace deviceAddressSpace = 4; */
        if (message.deviceAddressSpace !== 0)
            writer.tag(4, WireType.Varint).int32(message.deviceAddressSpace);
        /* uint32 size = 5; */
        if (message.size !== 0)
            writer.tag(5, WireType.Varint).uint32(message.size);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message WriteMemoryResponse
 */
export const WriteMemoryResponse = new WriteMemoryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SingleReadMemoryRequest$Type extends MessageType<SingleReadMemoryRequest> {
    constructor() {
        super("SingleReadMemoryRequest", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "request", kind: "message", T: () => ReadMemoryRequest }
        ]);
    }
    create(value?: PartialMessage<SingleReadMemoryRequest>): SingleReadMemoryRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        if (value !== undefined)
            reflectionMergePartial<SingleReadMemoryRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SingleReadMemoryRequest): SingleReadMemoryRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* ReadMemoryRequest request */ 2:
                    message.request = ReadMemoryRequest.internalBinaryRead(reader, reader.uint32(), options, message.request);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SingleReadMemoryRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* ReadMemoryRequest request = 2; */
        if (message.request)
            ReadMemoryRequest.internalBinaryWrite(message.request, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SingleReadMemoryRequest
 */
export const SingleReadMemoryRequest = new SingleReadMemoryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SingleReadMemoryResponse$Type extends MessageType<SingleReadMemoryResponse> {
    constructor() {
        super("SingleReadMemoryResponse", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "response", kind: "message", T: () => ReadMemoryResponse }
        ]);
    }
    create(value?: PartialMessage<SingleReadMemoryResponse>): SingleReadMemoryResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        if (value !== undefined)
            reflectionMergePartial<SingleReadMemoryResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SingleReadMemoryResponse): SingleReadMemoryResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* ReadMemoryResponse response */ 2:
                    message.response = ReadMemoryResponse.internalBinaryRead(reader, reader.uint32(), options, message.response);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SingleReadMemoryResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* ReadMemoryResponse response = 2; */
        if (message.response)
            ReadMemoryResponse.internalBinaryWrite(message.response, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SingleReadMemoryResponse
 */
export const SingleReadMemoryResponse = new SingleReadMemoryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SingleWriteMemoryRequest$Type extends MessageType<SingleWriteMemoryRequest> {
    constructor() {
        super("SingleWriteMemoryRequest", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "request", kind: "message", T: () => WriteMemoryRequest }
        ]);
    }
    create(value?: PartialMessage<SingleWriteMemoryRequest>): SingleWriteMemoryRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        if (value !== undefined)
            reflectionMergePartial<SingleWriteMemoryRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SingleWriteMemoryRequest): SingleWriteMemoryRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* WriteMemoryRequest request */ 2:
                    message.request = WriteMemoryRequest.internalBinaryRead(reader, reader.uint32(), options, message.request);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SingleWriteMemoryRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* WriteMemoryRequest request = 2; */
        if (message.request)
            WriteMemoryRequest.internalBinaryWrite(message.request, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SingleWriteMemoryRequest
 */
export const SingleWriteMemoryRequest = new SingleWriteMemoryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class SingleWriteMemoryResponse$Type extends MessageType<SingleWriteMemoryResponse> {
    constructor() {
        super("SingleWriteMemoryResponse", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "response", kind: "message", T: () => WriteMemoryResponse }
        ]);
    }
    create(value?: PartialMessage<SingleWriteMemoryResponse>): SingleWriteMemoryResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        if (value !== undefined)
            reflectionMergePartial<SingleWriteMemoryResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: SingleWriteMemoryResponse): SingleWriteMemoryResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* WriteMemoryResponse response */ 2:
                    message.response = WriteMemoryResponse.internalBinaryRead(reader, reader.uint32(), options, message.response);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: SingleWriteMemoryResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* WriteMemoryResponse response = 2; */
        if (message.response)
            WriteMemoryResponse.internalBinaryWrite(message.response, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message SingleWriteMemoryResponse
 */
export const SingleWriteMemoryResponse = new SingleWriteMemoryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MultiReadMemoryRequest$Type extends MessageType<MultiReadMemoryRequest> {
    constructor() {
        super("MultiReadMemoryRequest", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "requests", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ReadMemoryRequest }
        ]);
    }
    create(value?: PartialMessage<MultiReadMemoryRequest>): MultiReadMemoryRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.requests = [];
        if (value !== undefined)
            reflectionMergePartial<MultiReadMemoryRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MultiReadMemoryRequest): MultiReadMemoryRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* repeated ReadMemoryRequest requests */ 2:
                    message.requests.push(ReadMemoryRequest.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MultiReadMemoryRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* repeated ReadMemoryRequest requests = 2; */
        for (let i = 0; i < message.requests.length; i++)
            ReadMemoryRequest.internalBinaryWrite(message.requests[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MultiReadMemoryRequest
 */
export const MultiReadMemoryRequest = new MultiReadMemoryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MultiReadMemoryResponse$Type extends MessageType<MultiReadMemoryResponse> {
    constructor() {
        super("MultiReadMemoryResponse", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "responses", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ReadMemoryResponse }
        ]);
    }
    create(value?: PartialMessage<MultiReadMemoryResponse>): MultiReadMemoryResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.responses = [];
        if (value !== undefined)
            reflectionMergePartial<MultiReadMemoryResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MultiReadMemoryResponse): MultiReadMemoryResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* repeated ReadMemoryResponse responses */ 2:
                    message.responses.push(ReadMemoryResponse.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MultiReadMemoryResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* repeated ReadMemoryResponse responses = 2; */
        for (let i = 0; i < message.responses.length; i++)
            ReadMemoryResponse.internalBinaryWrite(message.responses[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MultiReadMemoryResponse
 */
export const MultiReadMemoryResponse = new MultiReadMemoryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MultiWriteMemoryRequest$Type extends MessageType<MultiWriteMemoryRequest> {
    constructor() {
        super("MultiWriteMemoryRequest", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "requests", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WriteMemoryRequest }
        ]);
    }
    create(value?: PartialMessage<MultiWriteMemoryRequest>): MultiWriteMemoryRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.requests = [];
        if (value !== undefined)
            reflectionMergePartial<MultiWriteMemoryRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MultiWriteMemoryRequest): MultiWriteMemoryRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* repeated WriteMemoryRequest requests */ 2:
                    message.requests.push(WriteMemoryRequest.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MultiWriteMemoryRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* repeated WriteMemoryRequest requests = 2; */
        for (let i = 0; i < message.requests.length; i++)
            WriteMemoryRequest.internalBinaryWrite(message.requests[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MultiWriteMemoryRequest
 */
export const MultiWriteMemoryRequest = new MultiWriteMemoryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MultiWriteMemoryResponse$Type extends MessageType<MultiWriteMemoryResponse> {
    constructor() {
        super("MultiWriteMemoryResponse", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "responses", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => WriteMemoryResponse }
        ]);
    }
    create(value?: PartialMessage<MultiWriteMemoryResponse>): MultiWriteMemoryResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.responses = [];
        if (value !== undefined)
            reflectionMergePartial<MultiWriteMemoryResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MultiWriteMemoryResponse): MultiWriteMemoryResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* repeated WriteMemoryResponse responses */ 2:
                    message.responses.push(WriteMemoryResponse.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MultiWriteMemoryResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* repeated WriteMemoryResponse responses = 2; */
        for (let i = 0; i < message.responses.length; i++)
            WriteMemoryResponse.internalBinaryWrite(message.responses[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MultiWriteMemoryResponse
 */
export const MultiWriteMemoryResponse = new MultiWriteMemoryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReadDirectoryRequest$Type extends MessageType<ReadDirectoryRequest> {
    constructor() {
        super("ReadDirectoryRequest", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ReadDirectoryRequest>): ReadDirectoryRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.path = "";
        if (value !== undefined)
            reflectionMergePartial<ReadDirectoryRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReadDirectoryRequest): ReadDirectoryRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* string path */ 2:
                    message.path = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReadDirectoryRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* string path = 2; */
        if (message.path !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.path);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ReadDirectoryRequest
 */
export const ReadDirectoryRequest = new ReadDirectoryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class DirEntry$Type extends MessageType<DirEntry> {
    constructor() {
        super("DirEntry", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "type", kind: "enum", T: () => ["DirEntryType", DirEntryType] }
        ]);
    }
    create(value?: PartialMessage<DirEntry>): DirEntry {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.type = 0;
        if (value !== undefined)
            reflectionMergePartial<DirEntry>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: DirEntry): DirEntry {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* DirEntryType type */ 2:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: DirEntry, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* DirEntryType type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message DirEntry
 */
export const DirEntry = new DirEntry$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ReadDirectoryResponse$Type extends MessageType<ReadDirectoryResponse> {
    constructor() {
        super("ReadDirectoryResponse", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "entries", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => DirEntry }
        ]);
    }
    create(value?: PartialMessage<ReadDirectoryResponse>): ReadDirectoryResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.path = "";
        message.entries = [];
        if (value !== undefined)
            reflectionMergePartial<ReadDirectoryResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ReadDirectoryResponse): ReadDirectoryResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* string path */ 2:
                    message.path = reader.string();
                    break;
                case /* repeated DirEntry entries */ 3:
                    message.entries.push(DirEntry.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ReadDirectoryResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* string path = 2; */
        if (message.path !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.path);
        /* repeated DirEntry entries = 3; */
        for (let i = 0; i < message.entries.length; i++)
            DirEntry.internalBinaryWrite(message.entries[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ReadDirectoryResponse
 */
export const ReadDirectoryResponse = new ReadDirectoryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MakeDirectoryRequest$Type extends MessageType<MakeDirectoryRequest> {
    constructor() {
        super("MakeDirectoryRequest", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MakeDirectoryRequest>): MakeDirectoryRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.path = "";
        if (value !== undefined)
            reflectionMergePartial<MakeDirectoryRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MakeDirectoryRequest): MakeDirectoryRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* string path */ 2:
                    message.path = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MakeDirectoryRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* string path = 2; */
        if (message.path !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.path);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MakeDirectoryRequest
 */
export const MakeDirectoryRequest = new MakeDirectoryRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class MakeDirectoryResponse$Type extends MessageType<MakeDirectoryResponse> {
    constructor() {
        super("MakeDirectoryResponse", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<MakeDirectoryResponse>): MakeDirectoryResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.path = "";
        if (value !== undefined)
            reflectionMergePartial<MakeDirectoryResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: MakeDirectoryResponse): MakeDirectoryResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* string path */ 2:
                    message.path = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: MakeDirectoryResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* string path = 2; */
        if (message.path !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.path);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message MakeDirectoryResponse
 */
export const MakeDirectoryResponse = new MakeDirectoryResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveFileRequest$Type extends MessageType<RemoveFileRequest> {
    constructor() {
        super("RemoveFileRequest", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RemoveFileRequest>): RemoveFileRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.path = "";
        if (value !== undefined)
            reflectionMergePartial<RemoveFileRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveFileRequest): RemoveFileRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* string path */ 2:
                    message.path = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveFileRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* string path = 2; */
        if (message.path !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.path);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RemoveFileRequest
 */
export const RemoveFileRequest = new RemoveFileRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RemoveFileResponse$Type extends MessageType<RemoveFileResponse> {
    constructor() {
        super("RemoveFileResponse", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RemoveFileResponse>): RemoveFileResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.path = "";
        if (value !== undefined)
            reflectionMergePartial<RemoveFileResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RemoveFileResponse): RemoveFileResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* string path */ 2:
                    message.path = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RemoveFileResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* string path = 2; */
        if (message.path !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.path);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RemoveFileResponse
 */
export const RemoveFileResponse = new RemoveFileResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RenameFileRequest$Type extends MessageType<RenameFileRequest> {
    constructor() {
        super("RenameFileRequest", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "newFilename", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RenameFileRequest>): RenameFileRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.path = "";
        message.newFilename = "";
        if (value !== undefined)
            reflectionMergePartial<RenameFileRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RenameFileRequest): RenameFileRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* string path */ 2:
                    message.path = reader.string();
                    break;
                case /* string newFilename */ 3:
                    message.newFilename = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RenameFileRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* string path = 2; */
        if (message.path !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.path);
        /* string newFilename = 3; */
        if (message.newFilename !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.newFilename);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RenameFileRequest
 */
export const RenameFileRequest = new RenameFileRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class RenameFileResponse$Type extends MessageType<RenameFileResponse> {
    constructor() {
        super("RenameFileResponse", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "newFilename", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<RenameFileResponse>): RenameFileResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.path = "";
        message.newFilename = "";
        if (value !== undefined)
            reflectionMergePartial<RenameFileResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: RenameFileResponse): RenameFileResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* string path */ 2:
                    message.path = reader.string();
                    break;
                case /* string newFilename */ 3:
                    message.newFilename = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: RenameFileResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* string path = 2; */
        if (message.path !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.path);
        /* string newFilename = 3; */
        if (message.newFilename !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.newFilename);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message RenameFileResponse
 */
export const RenameFileResponse = new RenameFileResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PutFileRequest$Type extends MessageType<PutFileRequest> {
    constructor() {
        super("PutFileRequest", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<PutFileRequest>): PutFileRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.path = "";
        message.data = new Uint8Array(0);
        if (value !== undefined)
            reflectionMergePartial<PutFileRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PutFileRequest): PutFileRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* string path */ 2:
                    message.path = reader.string();
                    break;
                case /* bytes data */ 3:
                    message.data = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PutFileRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* string path = 2; */
        if (message.path !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.path);
        /* bytes data = 3; */
        if (message.data.length)
            writer.tag(3, WireType.LengthDelimited).bytes(message.data);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PutFileRequest
 */
export const PutFileRequest = new PutFileRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class PutFileResponse$Type extends MessageType<PutFileResponse> {
    constructor() {
        super("PutFileResponse", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "size", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<PutFileResponse>): PutFileResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.path = "";
        message.size = 0;
        if (value !== undefined)
            reflectionMergePartial<PutFileResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: PutFileResponse): PutFileResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* string path */ 2:
                    message.path = reader.string();
                    break;
                case /* uint32 size */ 3:
                    message.size = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: PutFileResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* string path = 2; */
        if (message.path !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.path);
        /* uint32 size = 3; */
        if (message.size !== 0)
            writer.tag(3, WireType.Varint).uint32(message.size);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message PutFileResponse
 */
export const PutFileResponse = new PutFileResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFileRequest$Type extends MessageType<GetFileRequest> {
    constructor() {
        super("GetFileRequest", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<GetFileRequest>): GetFileRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.path = "";
        if (value !== undefined)
            reflectionMergePartial<GetFileRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFileRequest): GetFileRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* string path */ 2:
                    message.path = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFileRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* string path = 2; */
        if (message.path !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.path);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetFileRequest
 */
export const GetFileRequest = new GetFileRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFileResponse$Type extends MessageType<GetFileResponse> {
    constructor() {
        super("GetFileResponse", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "size", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 4, name: "data", kind: "scalar", T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<GetFileResponse>): GetFileResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.path = "";
        message.size = 0;
        message.data = new Uint8Array(0);
        if (value !== undefined)
            reflectionMergePartial<GetFileResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFileResponse): GetFileResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* string path */ 2:
                    message.path = reader.string();
                    break;
                case /* uint32 size */ 3:
                    message.size = reader.uint32();
                    break;
                case /* bytes data */ 4:
                    message.data = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFileResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* string path = 2; */
        if (message.path !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.path);
        /* uint32 size = 3; */
        if (message.size !== 0)
            writer.tag(3, WireType.Varint).uint32(message.size);
        /* bytes data = 4; */
        if (message.data.length)
            writer.tag(4, WireType.LengthDelimited).bytes(message.data);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message GetFileResponse
 */
export const GetFileResponse = new GetFileResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BootFileRequest$Type extends MessageType<BootFileRequest> {
    constructor() {
        super("BootFileRequest", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BootFileRequest>): BootFileRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.path = "";
        if (value !== undefined)
            reflectionMergePartial<BootFileRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BootFileRequest): BootFileRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* string path */ 2:
                    message.path = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BootFileRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* string path = 2; */
        if (message.path !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.path);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BootFileRequest
 */
export const BootFileRequest = new BootFileRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class BootFileResponse$Type extends MessageType<BootFileResponse> {
    constructor() {
        super("BootFileResponse", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "path", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<BootFileResponse>): BootFileResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.path = "";
        if (value !== undefined)
            reflectionMergePartial<BootFileResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: BootFileResponse): BootFileResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* string path */ 2:
                    message.path = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: BootFileResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* string path = 2; */
        if (message.path !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.path);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message BootFileResponse
 */
export const BootFileResponse = new BootFileResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FieldsRequest$Type extends MessageType<FieldsRequest> {
    constructor() {
        super("FieldsRequest", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "fields", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["Field", Field] }
        ]);
    }
    create(value?: PartialMessage<FieldsRequest>): FieldsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.fields = [];
        if (value !== undefined)
            reflectionMergePartial<FieldsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FieldsRequest): FieldsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* repeated Field fields */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.fields.push(reader.int32());
                    else
                        message.fields.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FieldsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* repeated Field fields = 2; */
        if (message.fields.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.fields.length; i++)
                writer.int32(message.fields[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FieldsRequest
 */
export const FieldsRequest = new FieldsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class FieldsResponse$Type extends MessageType<FieldsResponse> {
    constructor() {
        super("FieldsResponse", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "fields", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["Field", Field] },
            { no: 3, name: "values", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<FieldsResponse>): FieldsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.fields = [];
        message.values = [];
        if (value !== undefined)
            reflectionMergePartial<FieldsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: FieldsResponse): FieldsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* repeated Field fields */ 2:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.fields.push(reader.int32());
                    else
                        message.fields.push(reader.int32());
                    break;
                case /* repeated string values */ 3:
                    message.values.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: FieldsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* repeated Field fields = 2; */
        if (message.fields.length) {
            writer.tag(2, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.fields.length; i++)
                writer.int32(message.fields[i]);
            writer.join();
        }
        /* repeated string values = 3; */
        for (let i = 0; i < message.values.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.values[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message FieldsResponse
 */
export const FieldsResponse = new FieldsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NWACommandRequest$Type extends MessageType<NWACommandRequest> {
    constructor() {
        super("NWACommandRequest", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "command", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "args", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "binaryArg", kind: "scalar", opt: true, T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<NWACommandRequest>): NWACommandRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.command = "";
        message.args = "";
        if (value !== undefined)
            reflectionMergePartial<NWACommandRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NWACommandRequest): NWACommandRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* string command */ 2:
                    message.command = reader.string();
                    break;
                case /* string args */ 3:
                    message.args = reader.string();
                    break;
                case /* optional bytes binaryArg */ 4:
                    message.binaryArg = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NWACommandRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* string command = 2; */
        if (message.command !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.command);
        /* string args = 3; */
        if (message.args !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.args);
        /* optional bytes binaryArg = 4; */
        if (message.binaryArg !== undefined)
            writer.tag(4, WireType.LengthDelimited).bytes(message.binaryArg);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message NWACommandRequest
 */
export const NWACommandRequest = new NWACommandRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NWACommandResponse$Type extends MessageType<NWACommandResponse> {
    constructor() {
        super("NWACommandResponse", [
            { no: 1, name: "uri", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "asciiReply", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NWACommandResponse_NWAASCIIItem },
            { no: 3, name: "binaryReplay", kind: "scalar", opt: true, T: 12 /*ScalarType.BYTES*/ }
        ]);
    }
    create(value?: PartialMessage<NWACommandResponse>): NWACommandResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.uri = "";
        message.asciiReply = [];
        if (value !== undefined)
            reflectionMergePartial<NWACommandResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NWACommandResponse): NWACommandResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string uri */ 1:
                    message.uri = reader.string();
                    break;
                case /* repeated NWACommandResponse.NWAASCIIItem asciiReply */ 2:
                    message.asciiReply.push(NWACommandResponse_NWAASCIIItem.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* optional bytes binaryReplay */ 3:
                    message.binaryReplay = reader.bytes();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NWACommandResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string uri = 1; */
        if (message.uri !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.uri);
        /* repeated NWACommandResponse.NWAASCIIItem asciiReply = 2; */
        for (let i = 0; i < message.asciiReply.length; i++)
            NWACommandResponse_NWAASCIIItem.internalBinaryWrite(message.asciiReply[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* optional bytes binaryReplay = 3; */
        if (message.binaryReplay !== undefined)
            writer.tag(3, WireType.LengthDelimited).bytes(message.binaryReplay);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message NWACommandResponse
 */
export const NWACommandResponse = new NWACommandResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NWACommandResponse_NWAASCIIItem$Type extends MessageType<NWACommandResponse_NWAASCIIItem> {
    constructor() {
        super("NWACommandResponse.NWAASCIIItem", [
            { no: 1, name: "item", kind: "map", K: 9 /*ScalarType.STRING*/, V: { kind: "scalar", T: 9 /*ScalarType.STRING*/ } }
        ]);
    }
    create(value?: PartialMessage<NWACommandResponse_NWAASCIIItem>): NWACommandResponse_NWAASCIIItem {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.item = {};
        if (value !== undefined)
            reflectionMergePartial<NWACommandResponse_NWAASCIIItem>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NWACommandResponse_NWAASCIIItem): NWACommandResponse_NWAASCIIItem {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* map<string, string> item */ 1:
                    this.binaryReadMap1(message.item, reader, options);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    private binaryReadMap1(map: NWACommandResponse_NWAASCIIItem["item"], reader: IBinaryReader, options: BinaryReadOptions): void {
        let len = reader.uint32(), end = reader.pos + len, key: keyof NWACommandResponse_NWAASCIIItem["item"] | undefined, val: NWACommandResponse_NWAASCIIItem["item"][any] | undefined;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case 1:
                    key = reader.string();
                    break;
                case 2:
                    val = reader.string();
                    break;
                default: throw new globalThis.Error("unknown map entry field for field NWACommandResponse.NWAASCIIItem.item");
            }
        }
        map[key ?? ""] = val ?? "";
    }
    internalBinaryWrite(message: NWACommandResponse_NWAASCIIItem, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* map<string, string> item = 1; */
        for (let k of globalThis.Object.keys(message.item))
            writer.tag(1, WireType.LengthDelimited).fork().tag(1, WireType.LengthDelimited).string(k).tag(2, WireType.LengthDelimited).string(message.item[k]).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message NWACommandResponse.NWAASCIIItem
 */
export const NWACommandResponse_NWAASCIIItem = new NWACommandResponse_NWAASCIIItem$Type();
/**
 * @generated ServiceType for protobuf service Devices
 */
export const Devices = new ServiceType("Devices", [
    { name: "ListDevices", options: {}, I: DevicesRequest, O: DevicesResponse }
]);
/**
 * @generated ServiceType for protobuf service DeviceControl
 */
export const DeviceControl = new ServiceType("DeviceControl", [
    { name: "ResetSystem", options: {}, I: ResetSystemRequest, O: ResetSystemResponse },
    { name: "ResetToMenu", options: {}, I: ResetToMenuRequest, O: ResetToMenuResponse },
    { name: "PauseUnpauseEmulation", options: {}, I: PauseEmulationRequest, O: PauseEmulationResponse },
    { name: "PauseToggleEmulation", options: {}, I: PauseToggleEmulationRequest, O: PauseToggleEmulationResponse }
]);
/**
 * @generated ServiceType for protobuf service DeviceMemory
 */
export const DeviceMemory = new ServiceType("DeviceMemory", [
    { name: "MappingDetect", options: {}, I: DetectMemoryMappingRequest, O: DetectMemoryMappingResponse },
    { name: "SingleRead", options: {}, I: SingleReadMemoryRequest, O: SingleReadMemoryResponse },
    { name: "SingleWrite", options: {}, I: SingleWriteMemoryRequest, O: SingleWriteMemoryResponse },
    { name: "MultiRead", options: {}, I: MultiReadMemoryRequest, O: MultiReadMemoryResponse },
    { name: "MultiWrite", options: {}, I: MultiWriteMemoryRequest, O: MultiWriteMemoryResponse },
    { name: "StreamRead", serverStreaming: true, clientStreaming: true, options: {}, I: MultiReadMemoryRequest, O: MultiReadMemoryResponse },
    { name: "StreamWrite", serverStreaming: true, clientStreaming: true, options: {}, I: MultiWriteMemoryRequest, O: MultiWriteMemoryResponse }
]);
/**
 * @generated ServiceType for protobuf service DeviceFilesystem
 */
export const DeviceFilesystem = new ServiceType("DeviceFilesystem", [
    { name: "ReadDirectory", options: {}, I: ReadDirectoryRequest, O: ReadDirectoryResponse },
    { name: "MakeDirectory", options: {}, I: MakeDirectoryRequest, O: MakeDirectoryResponse },
    { name: "RemoveFile", options: {}, I: RemoveFileRequest, O: RemoveFileResponse },
    { name: "RenameFile", options: {}, I: RenameFileRequest, O: RenameFileResponse },
    { name: "PutFile", options: {}, I: PutFileRequest, O: PutFileResponse },
    { name: "GetFile", options: {}, I: GetFileRequest, O: GetFileResponse },
    { name: "BootFile", options: {}, I: BootFileRequest, O: BootFileResponse }
]);
/**
 * @generated ServiceType for protobuf service DeviceInfo
 */
export const DeviceInfo = new ServiceType("DeviceInfo", [
    { name: "FetchFields", options: {}, I: FieldsRequest, O: FieldsResponse }
]);
/**
 * @generated ServiceType for protobuf service DeviceNWA
 */
export const DeviceNWA = new ServiceType("DeviceNWA", [
    { name: "NWACommand", options: {}, I: NWACommandRequest, O: NWACommandResponse }
]);
